body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image img {
  height: 100%;
  object-fit: cover;
}

.autocomplete-control {
  position: relative;
}

.autocomplete {
  position: absolute;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  bottom: 100%;
  left: 0;
  right: 0;
  border: 1px solid grey;
  border-radius: 5px;
  max-height: 30vh;
  overflow-y: scroll;
}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-item:hover {
  background-color: #ddd;
}
.badge {
  position: absolute;
  top: 5px;
  right: -5px;
  padding: 2px 6px;
  border-radius: 50%;
  background: red;
  font-size: x-small;
  color: white;
  z-index: 99;
}
